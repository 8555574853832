'use client';

import { useEffect } from 'react';
import { logErrorToFirebase } from './lib/firebase/logging';
import LinkWithLoading from './components/link_with_loading';
export default function Error({
    error,
    reset,
}: {
    error: Error & { digest?: string };
    reset: () => void;
}) {
    useEffect(() => {
        if (typeof window !== 'undefined' && window.location.hostname === 'localhost') {
            return;
        }

        logErrorToFirebase(error, {
            type: 'route',
            digest: error.digest,
            origin: 'error-boundary'
        });
    }, [error]);

    return (
        <div className="min-h-screen p-8 flex items-center justify-center">
            <div className="max-w-2xl w-full bg-[#1a1a2e]/50 rounded-xl p-8 backdrop-blur-sm text-white">
                <h2 className="text-2xl font-['Thick'] mb-4 text-[#E0B1F1]">
                    Oops! We got too Litty
                </h2>
                <p className="text-gray-200 mb-6">
                    We've hit an unexpected snag. Our team has been notified and is working to get things back on track.
                </p>
                <div className="space-y-4">
                    <button
                        onClick={reset}
                        className="w-full bg-[#E0B1F1] hover:bg-[#d396ed] text-[#1a1a2e] font-semibold py-3 px-6 rounded-lg transition-colors"
                    >
                        Try again
                    </button>
                    <div className="flex flex-col sm:flex-row gap-4">
                        <LinkWithLoading
                            href="/"
                            className="flex-1 text-center bg-[#1a1a2e] hover:bg-[#252542] border border-[#E0B1F1] text-[#E0B1F1] font-semibold py-3 px-6 rounded-lg transition-colors"
                        >
                            Return home
                        </LinkWithLoading>
                        <LinkWithLoading
                            href="/contact"
                            className="flex-1 text-center bg-[#1a1a2e] hover:bg-[#252542] border border-[#E0B1F1] text-[#E0B1F1] font-semibold py-3 px-6 rounded-lg transition-colors"
                        >
                            Contact support
                        </LinkWithLoading>
                    </div>
                </div>
            </div>
        </div>
    );
}